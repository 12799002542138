.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 30px;
}

.linkItemBox {
    display: flex;
    flex-direction: column;
}
.linkItem {
    box-shadow: 0 2px 5px rgba(0,0,0,.25);
    height: 100px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size:16px;
}
    

.commonText {
    font-size: 20px;
    margin-top: 20px;
}

.tagBox {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
